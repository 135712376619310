jQuery(function($){
  'use strict';

  const body = document.body;
  var mobileOnly = window.matchMedia( "(max-width: 767px)" );
  var tabsOnly = window.matchMedia( "(max-width: 991px)" );
  var footerHeight = $("footer").outerHeight(true)

  function headerHideRevealFn(){
		const scrollUp = "scroll-up";
		const scrollDown = "scroll-down";
    const headerBackground = "headerBackground";
		let lastScroll = 0;

		window.addEventListener("scroll", () => {
			const currentScroll = window.pageYOffset;
      if(currentScroll < 150) {
        $("body #header").removeClass("headerBackground");
        $("body").removeClass("scrollDown");
      }
      if (currentScroll >= 150) {
        $("body #header").addClass("headerBackground");
        $("body").addClass("scrollDown");
			}

			// if (currentScroll <= 400) {
			// 	body.classList.remove(scrollUp);
      //   // $("body #header").addClass("headerBackground")
			// 	return;
			// }
		
			// if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
			// 	// down
			// 	body.classList.remove(scrollUp);
			// 	body.classList.add(scrollDown);
			// 	// lottiePlayer.play();
			// } else if (
			// 	currentScroll < lastScroll &&
			// 	body.classList.contains(scrollDown)
			// ) {
			// 	// up
			// 	body.classList.remove(scrollDown);
			// 	body.classList.add(scrollUp);
			// 	// lottiePlayer.stop();
			// }
			// lastScroll = currentScroll;
		});
	}

  //Accordion
  function accordion(){
    $('.accordion').find('.accordion__title').click(function(){
      // Adds active class
      $(this).toggleClass('active');
      // Expand or collapse this panel
      $(this).next().slideToggle('fast');
      // Hide the other panels
      $('.accordion__content').not($(this).next()).slideUp('fast');
      // Removes active class from other titles
      $('.accordion__title').not($(this)).removeClass('active');    
    });
  }

  $(document).ready(function() {
    
    var headerHeight = $('#header').outerHeight(true);
    console.log("Header Height", headerHeight);
    // $('main').css('paddingTop', headerHeight);

    //Mobile menu
    // $(".MenuBar").click(function(e){
    //   e.preventDefault();
    //   $("#header .NavContainer").addClass("Active_Nav");
    // });
    // $(".MenuOverlay").click(function(e){
    //   e.preventDefault();
    //   $("#header .NavContainer").removeClass("Active_Nav");
    // });
    $('.MobileNavController').click(function(event) {
			event.preventDefault()
			event.stopPropagation()
			$('body').toggleClass('ShowMenu')
		});

    headerHideRevealFn();

    //Covid-19 slider
    $('.covid19Slider').slick({
      infinite: true,
      slidesToShow: 1, 
      slidesToScroll: 1, 
      speed: 1000,
      autoplaySpeed: 4000,
      arrows: true,
      dots: true,
      autoplay: true
    });


    $("input:checkbox").on('click', function() {
      var $box = $(this);
      if ($box.is(":checked")) {
        var group = "input:checkbox[name='" + $box.attr("name") + "']";
        $(group).prop("checked", false);
        $box.prop("checked", true);
      } else {
        $box.prop("checked", false);
      }
    });

    var nextHeightArray = [];
    $('.stickyArea').nextAll().each(function() {
      nextHeightArray.push($(this).outerHeight(true))
    });
    
    var nextHeightTotal = nextHeightArray.reduce(function(a, b){
      return a + b;
    }, 0);

    nextHeightTotal = nextHeightTotal + (footerHeight ? footerHeight : 0)
    console.log("nextHeightTotal:", nextHeightTotal);
    var stickyAreaHeight = $('.stickyArea').outerHeight(true)

    // console.log("nextHeightTotal:", stickyAreaHeight)

    $("#rightLogoSticky").sticky({
      topSpacing: 80,
      bottomSpacing: nextHeightTotal + 5,
    });


    // var nextHeightArray2 = [];
    // $('.stickyArea2').nextAll().each(function() {
    //   nextHeightArray2.push($(this).outerHeight(true))
    // });
    // var nextHeightTotal2 = nextHeightArray2.reduce(function(a, b){
    //   return a + b;
    // }, 0);
    // nextHeightTotal2 = nextHeightTotal2 + (footerHeight ? footerHeight : 0)
    // $("#founderSticky").sticky({
    //   topSpacing: 105,
    //   bottomSpacing: nextHeightTotal2,
    // });


    var nextHeightArray3 = [];
    $('.blogSickyArea').nextAll().each(function() {
      nextHeightArray3.push($(this).outerHeight(true))
    });
    var nextHeightTotal3 = nextHeightArray3.reduce(function(a, b){
      return a + b;
    }, 0);

    nextHeightTotal3 = nextHeightTotal3 + (footerHeight ? footerHeight : 0)

    $("#blogRightSticky").sticky({
      topSpacing: 100,
      bottomSpacing: nextHeightTotal3 + 60,
    });

    if (mobileOnly.matches) {
      // console.log("window width is at less than 768px")
      $("#rightLogoSticky").unstick()
      // $("#rightLogoSticky").sticky({
      //   topSpacing: 80,
      //   bottomSpacing: nextHeightTotal,
      // });
      $("#blogRightSticky").unstick()
    }

    if (tabsOnly.matches) {
      $("#founderSticky").unstick()
    }
    

  }); //--End document.ready();
});